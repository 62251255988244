html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 200;
  margin: 0;
}

div {
  font-weight: 200;
}
