/* autoprefixer grid: autoplace */
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.Layout-appFrame-2 {
  flex: 1;
}

.Layout-contentWithSidebar-179 {
  height: 100vh;
}

.jss2 {
  flex: 1;
}

.Layout-content-19 {
  padding-left: 24px !important;
}

.jss4 {
  padding-left: 20px !important;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.sortableHelper {
  z-index: 10;
}

.activeMenuItem a {
  background: red;
}

.activeMenuItem {
  background: red;
}



.makeStyles-main-1 {
  justify-content: center !important;
}

.App-intro {
  font-size: large;
}

.datePickerTime>.react-datepicker__time-container {
  right: -45% !important;
  top: 5% !important;
}

.submenuText {
  color: #5bfcc2 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}